import React from 'react';
import { Helmet } from 'react-helmet';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  pageTitle: string;
}

const SEO = ({ pageTitle }: IProps) => {
  const {
    seoData: { description, title }
  } = useMVT();

  const siteTitle = title || 'ToolKit™';
  const siteUrl = process.env.GATSBY_SITE_URL || '';
  const titleText =
    pageTitle === 'Home' ? siteTitle : `${pageTitle} | ${siteTitle}`;

  return (
    <Helmet>
      <title>{titleText}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={siteUrl} />

      {/* facebook */}
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image:alt" content={pageTitle} />

      {/* twitter */}

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:alt" content={pageTitle} />
    </Helmet>
  );
};

export default SEO;
